export const Routes = {
    LOGIN: "/login",
    REGISTER: "/register",
    SELECT_PRACTICE: "/select-practice",
    DASHBOARD: "/dashboard",
    MANAGEMENT_DASHBOARD: "/management-dashboard",
    STAFF: "/staff",
    PRICE: "/price",
    SETTINGS: "/settings",
    PROFILE: "/profile",
    MY_DOCUMENTS: "/documents",
    COMMUNICATION: "/communication",
    MY_APPOINTMENTS: "/appointments",
    CONSULTATIONS: "/consultations",
    PATIENTS: "/patients",
    CHANGE_PASSWORD: "/change-password",
    HISTORY: "/history",
    MESSAGES: "/messages",
    APPOINTMENT: "/appointment",
    WORK_TIME: "/work-time",
    NOTIFICATIONS: "/all-notifications",
    MANAGE_PATIENTS_PAGE: "/m-patients",
    MANAGE_PATIENT_PAGE:"/m-patient",
    MANAGE_ACCOUNTING_PAGE:"/m-accounting",
    MANAGE_APPOINTMENTS:"/m-appointments",
    GROUP_APPOINTMENT_PAGE:"/group-appointment",
    SECURITY: "/security",
    TEST:"/test"
};
