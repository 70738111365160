import store from '../store';
import moment from 'moment';
import {refreshToken} from '../actions/auth_actions'
import {
    CLINICIAN_LOGIN_URL,
    GET_SPECIALTIES_URL,
    REFRESH_TOKEN_URL,
    REGISTER_URL
} from '../constants/api_paths';
import {formatUtils} from '../utils/formatUtils';

const STOP_RETRY = "STOP_RETRY";
const TOKEN_EXPIRED = "TOKEN_EXPIRED";
const NO_AUTH_API_URLS = [CLINICIAN_LOGIN_URL, REGISTER_URL, GET_SPECIALTIES_URL];

/**
 * Wrapper around fetch used as interceptor.
 * Check for token expiration before sending requests. If access token has expired send refresh request and check
 * other requests until the new tokens are returned, if the new tokens are returned successfuly re-send the pending requests.
 *
 * @returns {function} custom fetch
 */

//flag used to check if the refresh token request is already send
let refreshRequestSent = false;
const medRecFetch = (function (originalFetch) {


    //function used to check whether the api the requests is referring to does not require authentication
    function isNoAuthAPI(url) {
        return NO_AUTH_API_URLS.some(u => url.indexOf(u) !== -1);
    }

    //wrapper around fetch used as interceptor
    return function customFetch() {
        const authData = store.getState().authentication.data;
        let url = '';
        const request = arguments[1];
        const authorizationToken = getTokenHeader().Authorization;
        if (typeof arguments[0] === 'string' || typeof arguments[0] instanceof String) {
            url = arguments[0];
        } else if (typeof arguments[0] === 'object' || typeof arguments[0] instanceof Object) {
            url = arguments[0].href;
        }
        //If the url referrs to the refresh token api, send the request and if the result is successful reset the flag
        if (url.indexOf(REFRESH_TOKEN_URL) !== -1) {
            return originalFetch.apply(this, arguments).then((res) => {
                refreshRequestSent = false;
                return res;
            });
        }
        //Check if the access token has expired, if so check if there is any refresh request already sent and if there is one reject the current
        //request with message TOKEN_EXPIRED which markes the current request to be sent after an timeout.
        //If the refresh token is not yet sent, send it and also postpone the current request untill the refresh token response is ready
        if (authData && authData.access_token && (authData.authenticated_time + authData.access_token_validity) < moment().valueOf()) {
            if ((!authData.refreshRequest || (authData.refreshRequest && !authData.refreshRequest.sent && !authData.refreshRequest.finished)) && !refreshRequestSent) {
                refreshRequestSent = true;
                store.dispatch(refreshToken(authData.refresh_token));
            }
            return new Promise((resolve, reject) => reject(new Error(TOKEN_EXPIRED)));
        }
        //If the authorization token with the current stored authentication data is not the same as the authorization token in the request
        //and the url is does require authentication reject and re-try the request. This means that the request was sent before the refresh token
        //response was available, and it has old authorization token.
        //If there is no authorization token at all available, this means that the refresh request has failed, so stop retrying the current request
        if ((authorizationToken && authorizationToken !== request.headers.Authorization && !isNoAuthAPI(url)) || refreshRequestSent) {
            return new Promise((resolve, reject) => reject(new Error(TOKEN_EXPIRED)));
        } else if (!authorizationToken && !isNoAuthAPI(url)) {
            return new Promise((resolve, reject) => reject(new Error(STOP_RETRY)));
        }
        return originalFetch.apply(this, arguments);
    };
})(fetch);

/**
 * Wrapper around fetch, used to re-try calls if the token refresh request is sent and pending for response
 *
 * @param {string} url the url to send the POST request
 * @param {object} options the options to send
 * @returns {object} promise object
 */
const fetchWrapper = (url, options) => {
    return new Promise((resolve, reject) => {
        const fetchRetry = (url, options) => {
            return medRecFetch(url, options).then(resolve).catch(function (error) {
                if (error.message === TOKEN_EXPIRED) {
                    options.headers.Authorization = getTokenHeader().Authorization
                    setTimeout(() => {
                        fetchRetry(url, options);
                    }, 50)
                } else {
                    reject(error);
                }
            });
        }
        return fetchRetry(url, options);
    });
}

export const fetchHelper = {
    callPost,
    callGet,
    callPut,
    callDelete,
    uploadFile,
    getBlob,
    getBlobWithPost,
    getTokenHeader,
    callPostText
}

const CALL_TYPE = {
    POST: "POST",
    GET: "GET",
    PUT: "PUT",
    DELETE: "DELETE"
}

/**
 * Performs POST request to given url
 *
 * @param {string} url the url to send the POST request
 * @param {object} body the body to send
 * @returns {object} promise object
 */
export function callPost(url, body, params, isFormData, headers) {
    const urlPath = new URL(url);
    if (params) {
        Object.keys(params).forEach(key => urlPath.searchParams.append(key, params[key]));
    }
    let options = isFormData ? getRequestHeadersForFormData(CALL_TYPE.POST, body) : getRequestHeaders(CALL_TYPE.POST, body);
    if (headers) {
        options.headers = {...options.headers, ...headers}
    }
    return fetchWrapper(urlPath, options).then(handleResponse);
}


/**
 * Performs POST request with content type text
 *
 * @param {string} url the url to send the POST request
 * @param {object} body the body to send
 * @returns {object} promise object
 */
export function callPostText(url, body, params, isFormData, headers) {
    const urlPath = new URL(url);
    if (params) {
        Object.keys(params).forEach(key => urlPath.searchParams.append(key, params[key]));
    }
    let options = isFormData ? getRequestHeadersForFormData(CALL_TYPE.POST, body) : getRequestHeaders(CALL_TYPE.POST, body);
    if (headers) {
        options.headers = {...options.headers, ...headers}
    }
    options.body = body;
    return fetchWrapper(urlPath, options).then(handleResponse);
}

/**
 * Get the request headers to attach to the request.
 *
 * @param {string} callType method of the request
 * @param {object} body the body to send with the request
 * @returns {object} the request options
 */
function getRequestHeadersForFormData(callType, body) {
    const header = {
        //The browser sets the content type correctly depending on the form data content. It'll also set the first boundary! It should not be set manually!
        //'Content-Type': 'multipart/form-data'
    };
    const requestOptions = {
        method: callType,
        headers: {...getTokenHeader(), ...header},
    };
    if (callType === CALL_TYPE.POST || callType === CALL_TYPE.PUT) {
        requestOptions.body = body;
    }
    return requestOptions;
}

/**
 * Performs GET request to given url
 *
 * @param {string} url the url to send the GET request
 * @returns {object} promise object
 */
export function callGet(url, params, headers) {
    var urlPath = new URL(url);
    if (params) {
        Object.keys(params).forEach(key => urlPath.searchParams.append(key, params[key]));
    }

    let requestHeaders = getRequestHeaders(CALL_TYPE.GET);
    if (headers) {
        requestHeaders.headers = {...requestHeaders.headers, ...headers}
    }
    return fetchWrapper(urlPath, requestHeaders).then(handleResponse);
}

/**
 * Performs PUT request to given url
 *
 * @param {string} url the url to send the PUT request
 * @param {object} body the body to send
 * @param {object} params to send as query params
 * @returns {object} promise object
 */
export function callPut(url, body, params, isFormData) {
    let urlPath;
    if (params) {
        urlPath = new URL(url);
        Object.keys(params).forEach(key => urlPath.searchParams.append(key, params[key]));
    }
    return fetchWrapper(urlPath ? urlPath : url, isFormData ? getRequestHeadersForFormData(CALL_TYPE.PUT, body) : getRequestHeaders(CALL_TYPE.PUT, body)).then(handleResponse);
}

/**
 * Performs DELETE request to given url
 *
 * @param {string} url the url to send the DELETE request
 * @param {object} params to send as query params
 * @param {object} body to send with the request
 * @returns {object} promise object
 */
export function callDelete(url, params, body) {
    var urlPath = new URL(url);
    if (params) {
        Object.keys(params).forEach(key => urlPath.searchParams.append(key, params[key]));
    }
    return fetchWrapper(urlPath, getRequestHeaders(CALL_TYPE.DELETE, body)).then(handleResponse);
}

/**
 * Performs GET request to given url
 *
 * @param {string} url the url to send the GET request
 * @returns {object} promise object
 */
export function getBlob(url, params) {
    var urlPath = new URL(url);
    if (params) {
        Object.keys(params).forEach(key => urlPath.searchParams.append(key, params[key]));
    }
    return fetchWrapper(urlPath, getRequestHeaders(CALL_TYPE.GET)).then(handleBlobResponse);
}

/**
 * Performs POST request to given url and return blob response
 *
 * @url {string} url the url to send the POST request
 * @body {object} the request body
 * @params {object} params to send as query params
 * @returns {object} promise object
 */
export function getBlobWithPost(url, body, params) {
    const urlPath = new URL(url);
    if (params) {
        Object.keys(params).forEach(key => urlPath.searchParams.append(key, params[key]));
    }
    return fetchWrapper(urlPath, getRequestHeaders(CALL_TYPE.POST, body)).then(handleBlobResponse);
}

/**
 * Upload file to given url
 *
 * @param {string} url the url to send the GET request
 * @param {object} file the file to upload
 * @returns {object} promise object
 */
export function uploadFile(url, file) {
    const body = new FormData();
    body.append("file", file, file.name);

    const requestOptions = {
        method: "POST",
        headers: {
            ...getTokenHeader(),
        },
        body: body
    };

    return fetchWrapper(url, requestOptions).then(handleResponse);
}


/**
 * Handle response, check for errors and if there are any reject the call.
 *
 * @param {response} response the response object
 * @returns {object} promise object
 */
function handleResponse(response) {
    return response.text().then(text => {
        const data = formatUtils.isJSON(text) ? text && JSON.parse(text) : text;
        if (!response.ok) {
            return Promise.reject(data ? data : response);
        }
        return data;
    });
}


/**
 * Handle blob response, check for errors and if there are any reject the call.
 *
 * @param {response} response the response object
 * @returns {object} promise object
 */
function handleBlobResponse(response) {
    return response.blob().then(res => {
        if (!response.ok) {
            return Promise.reject();
        }
        return URL.createObjectURL(res);
    });
}

/**
 * Get the request headers to attach to the request.
 *
 * @param {string} callType method of the request
 * @param {object} body the body to send with the request
 * @returns {object} the request options
 */
function getRequestHeaders(callType, body) {
    const header = {
        'Content-Type': 'application/json'
    };
    const requestOptions = {
        method: callType,
        headers: {...getTokenHeader(), ...getLanguageHeader(), ...header},
    };
    if (callType === CALL_TYPE.POST || callType === CALL_TYPE.PUT || (callType === CALL_TYPE.DELETE && body)) {
        requestOptions.body = JSON.stringify(body);
    }
    return requestOptions;
}

/**
 * Get the authorization token
 *
 * @returns {object} the authorization token
 */
export function getTokenHeader() {
    // return authorization header with jwt token
    let authentication = store.getState().authentication.data;
    if (authentication && authentication.access_token) {
        return {'Authorization': 'Bearer ' + authentication.access_token};
    } else {
        return {};
    }
}

/**
 * Get language header
 */
export function getLanguageHeader() {
    let language = store.getState().language.selected.lang.toLowerCase();
    return {'Accept-Language' : language}
}



