import React from 'react'
import {connect} from 'react-redux'
import {
    displayProviderImage,
    fetchSpecialties
} from '../../actions/users_actions'
import {clearRegistrationData, register} from '../../actions/register_actions'
import {uploadLoggedUserDocument} from '../../actions/documents_actions'
import {logout} from '../../actions/auth_actions'
import {Wizard} from './wizard/Wizard'
import CenteredModal from '../shared/CenteredModal'
import {Routes} from '../../constants/routes'
import {$$} from '../../helpers/localization'
import PropTypes from "prop-types";


class Register extends React.Component {
    state = {
        showModal: false,
        showWizard: true
    }

    constructor(props) {
        super(props)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.registration.response && props.registration.response.id) {
            props.clearRegistrationData();
            props.displayProviderImage(false);
            return {
                showModal: true,
                showWizard: false,
            }
        }
        return state;
    }

    componentDidMount() {
        this.props.fetchSpecialties();
    }

    /**
     * Confirm the registration success modal, navigate to login
     */
    onConfirmModal = () => {
        try {
            Notification.requestPermission()
        } catch (error) {
            console.log(error)
        }

        if (this.props.userInfo.data.internal) {
            this.props.history.push(Routes.SELECT_PRACTICE);
        } else {
            this.props.history.push(Routes.DASHBOARD);
        }
    }

    /**
     * If canceling the modal, logout is performed and navigation to login
     */
    onCancelModal = () => {
        this.props.logout()
        this.props.history.push(Routes.LOGIN);
    }

    /**
     * Registers a particular provider and also saves his/her documents
     */
    registerProvider = (registrationFields, documents) => {
        this.props.register(registrationFields, documents);
    }

    render() {
        return (
            <div>
                {/** The Provider Vertical Card - displayed when the route is /profile (mode: update) */}
                <Wizard registration={this.props.registration}
                        specialties={this.props.specialties.specialties}
                        registerProvider={this.registerProvider}
                        showWizard={this.state.showWizard}
                        i18n={this.props.i18n}/>
                <CenteredModal title={$$('wizard_completed_modal_title')}
                               show={this.state.showModal}
                               onHide={() => this.setState({showModal: false})}
                               onConfirm={this.onConfirmModal}
                               onCancel={this.onCancelModal}
                               confirmBtnLabel={$$('login_label')}
                               cancelBtnLabel={$$('logout_label')}>
                    {$$('wizard_completed_modal_message')}
                </CenteredModal>
            </div>
        )
    }
}

Register.propTypes = {
    clearRegistrationData: PropTypes.func,
    displayProviderImage: PropTypes.func,
    fetchSpecialties: PropTypes.func,
    history: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    logout: PropTypes.func,
    match: PropTypes.object,
    register: PropTypes.func,
    registration: PropTypes.object,
    specialties: PropTypes.object,
    uploadLoggedUserDocument: PropTypes.func,
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo,
    registration: state.register,
    specialties: state.specialties,
    i18n: state.language
})

const mapDispatchToProps = {
    fetchSpecialties,
    register,
    clearRegistrationData,
    uploadLoggedUserDocument,
    displayProviderImage,
    logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
