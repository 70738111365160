import React from 'react'
import {authHelper} from '../../helpers/auth_helper';
import {Routes} from '../../constants/routes';
import {Redirect, Route, useLocation} from 'react-router-dom'
import Layout from "../../_metronic/layout/Layout";
import {logout} from '../../actions/auth_actions'
import {connect} from 'react-redux'
import PropTypes from "prop-types";

/* eslint-disable react/prop-types */
export function RouteWrapper({
                                 component: Component,
                                 isPrivate, managementLogin,
                                 organisationToManage,
                                 user,
                                 ...rest
                             }) {

    const isLoggedIn = authHelper.isLoggedIn();
    let query = new URLSearchParams(useLocation().search);
    /* eslint-enable react/prop-types */

    /**
     * Redirect user to Log in page if he tries to access a private route
     * without authentication.
     */
    if (isPrivate && !isLoggedIn) {
        return <Redirect to={Routes.LOGIN}/>;
    }

    /**
     * If the user has logged in as patient-management and hasn't chosen a practice to manage  send them back to the selection page
     */
    /* eslint-disable react/prop-types */
    if (isPrivate && isLoggedIn && user && user.internal && organisationToManage === "" && rest.path !== Routes.SELECT_PRACTICE) {
        return <Redirect to={Routes.SELECT_PRACTICE}/>;
    }
    /* eslint-enable react/prop-types */

    /**
     * If the target url is the login page, and the request comes from a link with username and password, logout current user
     */
    if (!isPrivate && isLoggedIn && rest.path === Routes.LOGIN && query.get("username") && query.get("password")) {
        rest.logout(query.get("username"), query.get("password"))
        return '';
    }

    /**
     * Redirect user to Main page if he tries to access a non private route
     * after being authenticated.
     */
    if (!isPrivate && isLoggedIn && user) {
        return <Redirect to={Routes.DASHBOARD}/>;
    }

    if (rest.isAdminMenu && !rest.administrativeRoute) {
        return <Redirect to={Routes.MANAGEMENT_DASHBOARD}/>;
    }

    if (!managementLogin && !rest.isAdminMenu && rest.administrativeRoute && !rest.clinicRoute) {
        return <Redirect to={Routes.DASHBOARD}/>;
    }

    //const Wrapper = isLoggedIn ? Layout : DefaultContainer;
    const Wrapper = isLoggedIn && !managementLogin ? Layout : undefined;

    /**
     * If not included on both previous cases, redirect user to the desired route.
     */
    return (
        <Route
            {...rest}
            render={props => (
                Wrapper ? <Wrapper>
                    <Component {...props} />
                </Wrapper> : <Component {...props} />
            )}
        />
    );
}

RouteWrapper.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    component: PropTypes.any,
    organisationToManage: PropTypes.any,
    isPrivate: PropTypes.any,
    managementLogin: PropTypes.bool,
    staticContext: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        organisationToManage: state.management.selectedOrganisation.id,
        user: state.userInfo.data.id ? state.userInfo.data : undefined,
        isAdminMenu: state.menu.selected === 2
    }
}

export default connect(mapStateToProps, {logout})(RouteWrapper)
