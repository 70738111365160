import {fetchHelper} from '../helpers/request_helper';
import {
    CLINIC_CREATE_APPOINTMENT,
    CLINIC_ENCOUNTER_BY_ID,
    CLINIC_FETCH_APPOINTMENTS,
    CLINIC_UPDATE_APPOINTMENT_STATUS_URL,
    GET_FIRST_AVAILABLE,
    GET_LATEST_REGISTRATION,
    GET_PROVIDER_TIMETABLE,
    ORG_PATIENTS,
    REGISTER_NEW_PATIENT,
    GET_CLINIC_REVENUE,
    GET_CLINIC_REVENUE_DETAILS,
    CLINIC_UPDATE_APPOINTMENT_URL,
    CLINIC_CREATE_GROUP_APPOINTMENT,
    GET_CLINIC_SUB_APPOINTMENTS_FOR_APPOINTMENT_URL,
    CLINIC_UPDATE_GROUP_APPOINTMENT_URL,
    CLINIC_UPDATE_APPOINTMENT_PAYMENT_STATUS_TO_PAID_URL,
    GET_CLINIC_CLINIC_PROVIDER_TEST_TEMPLATES_URL,
    CLINIC_CREATE_ENCOUNTER,
    CLINIC_SAVE_TEST_RESULTS_URL,
    CLINIC_DELETE_TEST_FIELD_VALUES_URL,
    CLINIC_GET_ENC_PATIENT_INFO,
    CLINIC_SAVE_ENC_PATIENT_INFO, CLINIC_UPDATE_PATIENT_PERSONAL_INFO, CLINIC_UPDATE_PATIENT_MEDICAL_PROFILE
} from '../constants/api_paths';
import {appointmentUtils} from "../utils/appointmentUtils";

export const clinicService = {
    fetchOrgPatients,
    registerPatient,
    fetchAppointments,
    updateAppointmentStatus,
    fetchAppointmentEncounter,
    fetchFirstHour,
    fetchTimetable,
    createAppointment,
    fetchLatestRegistrations,
    fetchRevenue,
    fetchRevenueDetails,
    updateAppointment,
    createGroupParentAppointment,
    getSubAppointmentsForAppointment,
    updateGroupAppointment,
    markAppointmentAsPaid,
    fetchProviderTestTemplates,
    createEncounter,
    saveTestsResults,
    deleteTestsResults,
    fetchEncPatientInfo,
    updateEncPatientInfo,
    updatePatientUserInfo,
    updatePatientMedicalProfile
}

/**
 * Fetch the organization patients
 * @param {string} org_id
 * @param params - pagination params
 * @return {object} promise object
 */
export function fetchOrgPatients(org_id, params) {
    return fetchHelper.callGet(ORG_PATIENTS.replace("{organizationId}", org_id), params);
}

/**
 * Create new patient and register to the organization
 * @param  {object} data - user registration data
 * @return {object} promise object
 */
export function registerPatient(data) {
    return fetchHelper.callPost(REGISTER_NEW_PATIENT, data)
}

/**
 * Fetch the appointments of the given user, org and provider
 *
 * @param {string} userId id of the user to fetch the appointments for
 * @param {string} orgId id of the organization to fetch the appointments for
 * @param {string} providerId id of the provider to fetch the appointments for
 * @param {object} params - optional params object containing the org_id, after and before properties
 * @returns {object} promise object
 */
export function fetchAppointments(userId, orgId, providerId, params) {
    if (!params) {
        params = {
            org_id: orgId,
            after: 0,
            before: appointmentUtils.calculateMaxFetchAppointmentsTimestamp()
        }
    }
    if (userId) {
        params.userId = userId;
        params.with_consultations = true;
    }
    if (providerId) {
        params.providerId = providerId;
    }
    return fetchHelper.callGet(CLINIC_FETCH_APPOINTMENTS, params);
}

/**
 * Update the appointment status
 * @param {string} appointmentId
 * @param {string} status
 * @param {object} note optional note object
 * @return {object} promise object
 */
export function updateAppointmentStatus(appointmentId, status, note) {
    return fetchHelper.callPut(CLINIC_UPDATE_APPOINTMENT_STATUS_URL.replace('{appointmentId}', appointmentId).replace('{status}', status), note);
}

/**
 * Mark the appointment as paid
 * @param {string} appointmentId
 * @return {object} promise object
 */
export function markAppointmentAsPaid(appointmentId) {
    return fetchHelper.callPut(CLINIC_UPDATE_APPOINTMENT_PAYMENT_STATUS_TO_PAID_URL.replace('{appointmentId}', appointmentId), {});
}

/**
 * Fetch an encounter by id
 * @param {string} encounterId
 * @return {object} promise object
 */
export function fetchAppointmentEncounter(encounterId) {
    return fetchHelper.callGet(CLINIC_ENCOUNTER_BY_ID.replace('{encounterId}', encounterId), null);
}

export function fetchFirstHour(providerId, startTimestamp, endTimestamp, timezone, location, organizationId, priceId) {
    let locationUrl = location ? "&location=" + location : "";
    let organizationIdUrl = organizationId ? "&organizationId=" + organizationId : ""
    return fetchHelper.callGet(GET_FIRST_AVAILABLE
        .replace('{providerId}', providerId)
        .replace('{startTimestamp}', startTimestamp)
        .replace('{endTimestamp}', endTimestamp)
        .replace('{timezone}', timezone)
        .replace('{location}', locationUrl)
        .replace('{organizationId}', organizationIdUrl)
        .replace('{priceId}', priceId)
    )
}


export function fetchTimetable(providerId, startTimestamp, endTimestamp, timezone, location, organizationId, priceId) {
    let locationUrl = location ? "&location=" + location : "";
    let organizationIdUrl = organizationId ? "&organizationId=" + organizationId : ""
    return fetchHelper.callGet(GET_PROVIDER_TIMETABLE
        .replace('{providerId}', providerId)
        .replace('{startTimestamp}', startTimestamp)
        .replace('{endTimestamp}', endTimestamp)
        .replace('{timezone}', timezone)
        .replace('{location}', locationUrl)
        .replace('{organizationId}', organizationIdUrl)
        .replace('{priceId}', priceId)
    )
}

export function createAppointment(appointment, targetUserId, forceCreateAppointment) {
    return fetchHelper.callPost(CLINIC_CREATE_APPOINTMENT.replace("{userId}", targetUserId), appointment, {force: !!forceCreateAppointment})
}

export function createGroupParentAppointment(appointment, forceCreateAppointment) {
    return fetchHelper.callPost(CLINIC_CREATE_GROUP_APPOINTMENT, appointment, {force: !!forceCreateAppointment})
}

export function fetchLatestRegistrations(orgId, limit) {
    return fetchHelper.callGet(GET_LATEST_REGISTRATION.replace("{organizationId}", orgId).replace("{limit}", limit));
}

export function fetchRevenue(providerId, orgId, before, after, bookedByDate, includeMarkedAsPaid) {
    let params = { after:after, before:before, bookedByDate:bookedByDate, includeMarkedAsPaid:includeMarkedAsPaid };
    if (providerId) {
        params.providerId = providerId;
    }
    return fetchHelper.callGet(GET_CLINIC_REVENUE.replace("{organizationId}", orgId), params);
}

export function fetchRevenueDetails(providerId, orgId, before, after,  bookedByDate, includeMarkedAsPaid) {
    let params = { after:after, before:before, org_id:orgId,  bookedByDate: bookedByDate, includeMarkedAsPaid:includeMarkedAsPaid, includeCanceled:true};
    if (providerId) {
        params.providerId = providerId;
    }
    return fetchHelper.callGet(GET_CLINIC_REVENUE_DETAILS.replace("{providerId}", providerId), params);
}

export function updateAppointment(appointment, forceUpdateAppointment) {
    return fetchHelper.callPut(CLINIC_UPDATE_APPOINTMENT_URL, appointment, {force: !!forceUpdateAppointment})
}

export function getSubAppointmentsForAppointment(appointmentId) {
    return fetchHelper.callGet(GET_CLINIC_SUB_APPOINTMENTS_FOR_APPOINTMENT_URL.replace("{appointmentId}", appointmentId))
}

export function updateGroupAppointment(appointment, forceUpdateAppointment) {
    return fetchHelper.callPut(CLINIC_UPDATE_GROUP_APPOINTMENT_URL, appointment, {force: !!forceUpdateAppointment})
}

export function fetchProviderTestTemplates(orgId, providerId) {
    return fetchHelper.callGet(GET_CLINIC_CLINIC_PROVIDER_TEST_TEMPLATES_URL.replace("{organizationId}", orgId).replace("{providerId}", providerId))
}

export function createEncounter(appointmentId, providerId, encounter) {
    return fetchHelper.callPost(CLINIC_CREATE_ENCOUNTER.replace("{appointmentId}", appointmentId).replace("{providerId}", providerId), encounter);
}

export function saveTestsResults(encounterId, test_results) {
    return fetchHelper.callPut(CLINIC_SAVE_TEST_RESULTS_URL.replace("{encounter_id}", encounterId), test_results);
}

export function deleteTestsResults(encounterId, templateId) {
    return fetchHelper.callDelete(CLINIC_DELETE_TEST_FIELD_VALUES_URL.replace("{encounter_id}", encounterId).replace("{template_id}", templateId));
}

export function fetchEncPatientInfo(patientId, orgId) {
    return fetchHelper.callGet(CLINIC_GET_ENC_PATIENT_INFO.replace("{patientId}", patientId).replace("{orgId}", orgId));
}

export function updateEncPatientInfo(patientId, orgId, data) {
    return fetchHelper.callPut(CLINIC_SAVE_ENC_PATIENT_INFO.replace("{patientId}", patientId).replace("{orgId}", orgId), data);
}

export function updatePatientUserInfo(info, orgId) {
    let params = {}
    if (orgId) {
        params.org_id = orgId;
    }
    return fetchHelper.callPut(CLINIC_UPDATE_PATIENT_PERSONAL_INFO, info, params, false);
}

export function updatePatientMedicalProfile(medicalProfile) {
    return fetchHelper.callPut(CLINIC_UPDATE_PATIENT_MEDICAL_PROFILE, medicalProfile, null, false);
}