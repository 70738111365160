import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {$$} from '../../helpers/localization'
import {FORGOT_PASSWORD_URL, PRIVACY_POLICY_URL} from '../../constants/api_paths'
import classnames from 'classnames'
import PropTypes from "prop-types";
import {authService} from "../../service/auth_service";


export class LoginForm extends Component {
    state = {
        email: '',
        password: '',
        code: '',
        formclass: '',
        errors: {}
    };

    constructor(props) {
        super(props);
    }

    static getDerivedStateFromProps(props) {
        if (props.email && props.password) {
            return {email: props.email, password: props.password};
        }
        return null;
    }

    componentDidMount() {
        if (this.props.email && this.props.password) {
            this.props.onLogin(this.props.email, this.props.password, null, true);
        } else {
            this.props.clearLoginResponse();
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.auth_data.request?.error === 'MUST_SEND_EMAIL_CODE' && !this.state.showEmailCode) {
            this.setState({showEmailCode: true, formclass:'', code:''});
        } else if (this.props.auth_data.request?.error === 'MUST_SEND_TOTP_CODE' && !this.state.showTotpCode) {
            this.setState({showTotpCode: true, formclass:'', code:''});
        } if ((this.props.auth_data.request?.error?.message === 'TEMP_LOCKED_DOWN' ||
                this.props.auth_data.request?.error?.message === 'MUST_RESET_PASSWORD') && (this.state.showEmailCode || this.state.showTotpCode)) {
            this.setState({showTotpCode: false, showEmailCode: false, formclass: '', code:'', login_2fa_email_sent:false});
        }
    }

    componentWillUnmount() {
        this.setState({
            email: '',
            password: '',
            code: '',
            formclass: '',
            showEmailCode:false,
            showTotpCode:false
        })
        this.props.clearLoginResponse();
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };


    /**
     * Form submit handler, validate data and set error in state if any. Call login action and clear state.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();

        try {
            Notification.requestPermission()
        } catch (error) {
            console.log(error)
        }

        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }

        if (evt.target.checkValidity() === true) {
            const email = this.state.email;
            const password = this.state.password;
            this.props.onLogin(email, password, false, this.state.code);
            this.setState({login_2fa_email_sent:false})
            /*this.setState({
                email: '',
                password: '',
                code: '',
                formclass: ''
            })*/

        }
    }

    /**
     * Open new tab with the forgot password url
     */
    onForgotPasswordClick = () => {
        window.open(FORGOT_PASSWORD_URL.replace("{lang}", this.props.i18n.selected.lang), "_blank");
    }

    /**
     * Open new tab with the privacy policy
     */
    onPrivacyPolicyClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(this.createPPUrl(), "_blank");
    }

    /**
     * Create a language aware Privacy Policy URL
     * @returns {string}
     */
    createPPUrl = () => {
        let lang = this.props.i18n.selected.lang;
        lang = lang.toString() === "sq" ? "en" : lang;
        return PRIVACY_POLICY_URL.replace("{lang}", lang)
    }


    /**
     * Get error message to display based on error code
     *
     * @returns {string} error message to display
     */
    getErrorMessage() {
        if (this.props.auth_data.request && this.props.auth_data.request?.error) {
            switch (this.props.auth_data.request?.error?.status) {
                case 401:
                    if (this.props.auth_data.request?.error?.message === 'TEMP_LOCKED_DOWN') {
                        return $$('login_form_temp_locked_down_message');
                    }
                    if (this.props.auth_data.request?.error?.message === 'MUST_RESET_PASSWORD') {
                        return $$('login_form_must_reset_password_message');
                    }
                    if (this.props.auth_data.request?.error?.message === 'INVALID_2FA_CODE') {
                        return $$('code_2fa_mismatch');
                    }
                    return $$('login_form_invalid_credentials_message');
                default:
                    return this.props.auth_data.request?.error?.message
            }
        } else if (this.props.auth_data.refreshRequest.error) {
            if (this.props.auth_data.refreshRequest.error.message === 'AUTO_LOGOUT') {
                return $$('auto_logout');
            }
            switch (this.props.auth_data.refreshRequest.error.status) {
                case 401:
                    return $$('refresh_token_expired_message');
                default:
                    return this.props.auth_data.refreshRequest.error.message
            }
        }
    }

    send2FAEmail = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // eslint-disable-next-line no-unused-vars
        authService.send2FAEmail(this.state.email).then((r) => {
            this.setState({login_2fa_email_sent:true})
        })
    }

    resetPage = () => {
        this.setState({
            email: '',
            password: '',
            code: '',
            formclass: '',
            showEmailCode:false,
            showTotpCode:false
        })
        this.props.logout();
    }

    render() {
        let errorMsg = '';
        if (this.props.auth_data.request && this.props.auth_data.request?.error || this.props.auth_data.refreshRequest?.error) {
            if (this.props.auth_data.request?.error !== 'MUST_SEND_EMAIL_CODE' && this.props.auth_data.request?.error !== 'MUST_SEND_TOTP_CODE')
            errorMsg = (
                <div className="alert alert-danger">
                    {this.getErrorMessage()}
                </div>
            );
        }

        const spinnerClass = classnames({
            "spinner-border": true,
            "hidden": !(this.props.email && this.props.password)
        });

        return (
            <div className="kt-login__body">
                <div className="kt-login__form">
                    <div className="kt-login__title">
                        <h1 className="text-center">{$$("login_label")}</h1>
                    </div>

                    <form onSubmit={this.onSubmit} className={classnames(this.state.formclass)} noValidate={true}>
                        {errorMsg}
                        {!(this.state.showEmailCode || this.state.showTotpCode) && <div className="form-group">
                            <input type="email" className="form-control" value={this.state.email}
                                   placeholder={$$('email_label')} name="email" onChange={this.onInputChange} required/>
                            <div className="invalid-feedback">
                                {$$('email_required_message')}
                            </div>
                        </div>}
                        {!(this.state.showEmailCode || this.state.showTotpCode) && <div className="form-group">
                            <input type="password" className="form-control" value={this.state.password}
                                   placeholder={$$('password_label')} name="password" onChange={this.onInputChange}
                                   required/>
                            <div className="invalid-feedback">
                                {$$('password_required_message')}
                            </div>
                        </div>}
                        {(this.state.showEmailCode || this.state.showTotpCode) && <div className={"pb-1"}>
                            {this.state.showEmailCode ? $$("login_verification_code_by_email") : $$("login_verification_code_by_totp") }
                        </div>}
                        {(this.state.showEmailCode || this.state.showTotpCode) && <div className="form-group">
                            <input type="text" className="form-control" value={this.state.code}
                                   placeholder={$$('two_fa_code')} name="code" onChange={this.onInputChange}
                                   required />
                            <div className="invalid-feedback">
                                {$$('code_2fa_mismatch')}
                            </div>
                        </div> }
                        { this.state.showEmailCode && <div className="form-group">
                            <a href="#" onClick={this.send2FAEmail}>{$$("send_2fa_email")}</a>
                        </div>}
                        {
                            this.state.login_2fa_email_sent && <div className="form-group text-danger">
                                {$$("email_2fa_sent")}
                            </div>
                        }
                        {this.state.showEmailCode &&
                        <div className="form-group"><div className={"help-block"}>
                                {$$("two_fa_code_explanation")}
                            </div>
                        </div>
                        }
                        <div className="form-group row">
                            <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                {!(this.state.showEmailCode || this.state.showTotpCode) ?
                                    <Link className="btn btn-secondary btn-block"
                                          to="/register">{$$("register_label")}</Link>
                                    :
                                   <button type="button" className="btn btn-secondary btn-block" onClick={this.resetPage}>
                                       {$$("back")}
                                   </button>
                                }
                            </div>
                            <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                <button type="submit" className="btn btn-success btn-block">
                                    {$$("login_label")}
                                    <div className={spinnerClass} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </button>
                            </div>

                        </div>
                        {/*<h4 className="text-center">{$$("administrative_portal_label")}</h4>
                        <div className="form-group row">
                            <div className="col-xs-12 col-md-12 mx-auto mt-2">
                                <button type="button" onClick={this.onProviderLogin}
                                        className="btn btn-primary btn-block">
                                    {$$("manage_your_practice")}
                                    <div className={spinnerClass} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </div>*/}
                        <div className="form-group text-center small font-weight-lighter">
                            <a href="#" onClick={this.onForgotPasswordClick}>{$$('forgot_password')}</a>
                        </div>
                        <div className="text-center">
                            <a href={this.createPPUrl()} onClick={this.onPrivacyPolicyClick}>{$$('privacy_policy')}</a>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

LoginForm.propTypes = {
    auth_data: PropTypes.object,
    email: PropTypes.string,
    i18n: PropTypes.object,
    logout: PropTypes.func,
    onLogin: PropTypes.func,
    clearLoginResponse: PropTypes.func,
    onManagementLogin: PropTypes.func,
    UpdateMenu: PropTypes.func,
    password: PropTypes.string
}


export default LoginForm
