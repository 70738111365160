import React, {Component} from 'react'
import classNames from 'classnames'
import {$$} from '../../helpers/localization';
import {getColorForOption, getResolvedText, NOTE_VISIBILITY} from '../../constants/select_options'
import moment from 'moment'
import _ from 'underscore';
import NoteSpeechToText from './NoteSpeechToText';
import {PRICES} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";


class NotesTabListItem extends Component {
    state = {
        encounter: null,
        descriptionOpened: false,
        isEditMode: false,
        value: '',
        note: '',
        formclass: '',
        errors: {},
        changingNote: false,
    }

    constructor(props) {
        super(props);
        this.participant = {};
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedNoteId !== this.props.note.id && this.state.encounter != null) {
            this.setState({
                encounter: null,
                descriptionOpened: false
            });
        }
        if (this.props.selectedNoteId !== this.props.note.id && this.state.encounter == null && this.state.descriptionOpened !== false) {
            this.setState({descriptionOpened: false});
        }
        if (this.props.selectedNote && ((prevProps.selectedNote !== this.props.selectedNote) ||
            (prevProps.selectedNote === this.props.selectedNote && (!this.state.value && this.props.selectedNote.value)))) {
            this.setState({value: this.props.selectedNote.value});
            this.setState({note: this.props.selectedNote.value});
        }
        if (this.props.selectedNoteId !== this.props.note.id && this.state.isEditMode) {
            this.setState({
                isEditMode: false
            });
        }
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call create logbook entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        this.setState({errors: ''});
        if (!this.state.note) {
            errors.note = 'note_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    onNoteSpeechChange = (text) => {
        this.setState({note: text});
    };

    /**
     * An event handler triggered when an note is clicked
     */
    onNoteClick = () => {
        this.props.onNoteChange(this.props.note);
    }

    /**
     * Update existing note
     */
    onSaveNote = () => {
        if (this.validate()) {
            this.setState({
                isEditMode: !this.state.isEditMode
            })
            let note = this.props.note;
            note.value = this.state.note;
            this.props.updateNote(note);
        }
    }

    getTitle = () => {
        if (this.props.selectedAppointment.id && this.props.note.appointmentId === this.props.selectedAppointment.id) {
            let title = this.props.selectedAppointment.health_issues.map((hi => hi.name)).join(", ");
            if (title === "") {
                return $$("note_label");
            }
            return title;
        } else {
            let appt = this.props.userAppointments.find(ap => ap.notes.find((note) => note.id === this.props.note.id));
            if (appt) {
                let title = appt.health_issues.map((hi => hi.name)).join(", ");
                if (title === "") {
                    return $$("note_label");
                }
                return title;
            }
        }
        return $$("general_note_label");
    }

    getOwnerName = () => {
        let name = "";
        if (this.props.note.owner_id === this.props.userId) {
            name = $$("You")//this.props.selectedUser.fullname;
        } else {
            name = this.props.note.owner_fullname;
        }
        return name;
    }

    render() {
        const activeClass = classNames('appointment-containter note', {
            'appointment-active': (this.props.selectedNoteId === this.props.note.id)
        });

        const activeDateClass = classNames('smaller-text medrec-grey-2', {
            'medrec-blue-1': (this.props.selectedNoteId === this.props.note.id)
        });

        const activeNoteButtonsClass = classNames('smaller-text medrec-blue-1 text-right note-actions', {
            'note-hidden': (this.props.selectedNoteId !== this.props.note.id)
        });

        const activeNoteStatusClass = classNames('smaller-text ', {
            'note-hidden': (this.props.selectedNoteId !== this.props.note.id && this.props.userId !== this.props.note.owner_id)
        });

        let lang = _.contains(['en', 'sq'], this.props.i18n) ? 'en' : this.props.i18n;
        moment.locale(lang.lang);

        return (
            <div>
                <div className={activeClass} onClick={this.onNoteClick}>
                    <div className="pr-2 pl-2">
                        <div className="w-100">
                            <div className="d-flex justify-content-between">
                            <div className={activeDateClass}>
                                <i className="flaticon-calendar-with-a-clock-time-tools"/>&nbsp;&nbsp;&nbsp;&nbsp;
                                {dateTimeUtils.getFormattedDateWithTime(this.props.note.server_created_timestamp)}
                                &nbsp;({this.getOwnerName()})
                            </div>
                            <div>
                                {(this.props.userId === this.props.note.owner_id || (this.props.userId !== this.props.note.owner_id && this.props.note.visibility === "PROVIDER_ONLY")) &&
                                <div className={activeNoteStatusClass}>
                                    <span className="medrec-grey-2">
                                            {$$("visibility_label")}:
                                    </span>
                                    &nbsp;
                                    <span
                                        style={{"color": getColorForOption(NOTE_VISIBILITY.COLOR, this.props.note.visibility)}}>
                                            {getResolvedText(NOTE_VISIBILITY.TYPE, this.props.note.visibility)}
                                        </span>
                                </div>}
                            </div>
                            </div>
                            {this.props.userId === this.props.note.owner_id && this.props.selectedNoteId === this.props.note.id &&
                            <div className={activeNoteButtonsClass}>
                                <a className="nav-link active" onClick={this.props.openDeleteModal}
                                   style={{"display": "inline-block"}}>
                                    <i className="kt-nav__link-icon flaticon-delete"/>&nbsp;{$$("delete_label")}
                                </a>
                                <a className="nav-link active" onClick={this.props.openVisibilityModal}
                                   style={{"display": "inline-block"}}>
                                    <i className="kt-nav__link-icon flaticon-share"/>&nbsp;{$$("visibility_label")}
                                </a>
                                <a className="nav-link active" onClick={() => {
                                    if (!this.state.isEditMode) {
                                        this.setState({
                                            isEditMode: !this.state.isEditMode,
                                            note: this.props.selectedNote.value
                                        })
                                    }
                                }} style={{"display": "inline-block"}}>
                                    <i className="kt-nav__link-icon flaticon-edit"/>&nbsp;{$$("edit_label")}
                                </a>
                            </div>}
                            {!this.state.isEditMode &&
                            <div className={classNames("note-content",
                                {"background-blue":this.props.note.owner_id === this.props.userId,
                                 "background-red":this.props.note.owner_id !== this.props.userId})}>
                                {((this.props.note.value.length > 280) && !this.state.descriptionOpened) ? (this.props.note.value.substr(0, 279) + "...") : this.props.note.value}
                            </div>}
                            {this.state.isEditMode &&
                            <div style={{"marginTop": "20px", "marginBottom": "20px"}}>
                                <FormWithLoading
                                    formDisabled={this.props.formDisabled}
                                    currentForm={PRICES}
                                    marginTop="10%"
                                    marginLeft="calc(50% - 70px)"
                                    id="logbookForm" onSubmit={this.onSubmit} className={this.state.formclass}
                                    noValidate={true}>
                                    <div className="form-group">
                                        <div>
                                            <NoteSpeechToText
                                                onInputChange={this.onInputChange}
                                                note={this.state.note}
                                                onNoteSpeechChange={this.onNoteSpeechChange}
                                                i18n={this.props.i18n}
                                            />
                                        </div>
                                        <div className="invalid-feedback">
                                            {$$('note_required_message')}
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{
                                        "float": "right",
                                        "marginRight": "10px",
                                        "marginTop": "-5px",
                                        "zIndex": "100"
                                    }}>
                                        <div>
                                            <button type="button" className="btn btn-secondary btn-block"
                                                    onClick={() => {
                                                        this.setState({isEditMode: !this.state.isEditMode})
                                                    }}>
                                                {$$("cancel_btn")}
                                            </button>
                                        </div>
                                        <div style={{"marginLeft": "7px"}}>
                                            <button type="button" className="btn btn-success btn-block"
                                                    onClick={this.onSaveNote}>
                                                {$$("save_btn_label")}
                                            </button>
                                        </div>
                                    </div>
                                </FormWithLoading>
                            </div>}

                        </div>
                    </div>
                    {this.props.note.value?.length > 280 && <div className="appointment-expand-cell medrec-blue-1 text-right"
                    >
                        <a className="smaller-text nav-link active" onClick={() => {
                            this.setState({descriptionOpened: !this.state.descriptionOpened})
                        }}>
                            {!this.state.descriptionOpened &&
                                <span> {$$("more")}  <i className="kt-nav__link-icon fas fa-chevron-down"/></span>
                            }
                            {this.state.descriptionOpened &&
                                <span> {$$("less")} <i className="kt-nav__link-icon fas fa-chevron-up"/> </span>
                            }
                        </a>
                    </div>}
                    {/*<hr/>*/}
                </div>

            </div>
        )
    }
}

NotesTabListItem.propTypes = {
    i18n: PropTypes.object,
    index: PropTypes.number,
    userAppointments: PropTypes.any,
    formDisabled: PropTypes.any,
    note: PropTypes.object,
    onNoteChange: PropTypes.func,
    openDeleteModal: PropTypes.func,
    openVisibilityModal: PropTypes.func,
    selectedAppointment: PropTypes.object,
    selectedNote: PropTypes.object,
    selectedNoteId: PropTypes.string,
    selectedUser: PropTypes.object,
    updateNote: PropTypes.func,
    userId: PropTypes.string,
};

export default NotesTabListItem;
