import React from 'react'
import {connect} from 'react-redux'
import {$$} from "../../helpers/localization";
import * as Proptypes from "prop-types";
import PracticeItem from "./PracticeItem";
import {SelectOrganisationToManage} from "../../actions/management_actions";
import LanguageSelector from "../layout/LanguageSelector";
import {logout} from "../../actions/auth_actions";
import history from "../../helpers/history";
import {Routes} from "../../constants/routes";

class PracticeList extends React.Component {

    state = {
        selectedId: "",
        selectedName: "",
    }

    constructor(props) {
        super(props)
    }

    onItemClick = (id, name) => {
        this.setState({
            selectedId: id,
            selectedName: name
        })
    }

    itemSelected = (id) => {
        return this.state.selectedId === id;
    }

    isButtonDisabled = () => {
        return this.state.selectedId === "";
    }

    disabledClass = (isDisabled) => {
        return isDisabled ? "disabled-practice-button" : ""
    }

    onBackClick = () => {
        if (!this.props.userInfo.data.internal) {
            history.push(Routes.DASHBOARD);
        } else {
            this.props.logout()
        }
    }

    render() {
        const practices = this.props.organizations && this.props.organizations.length > 0 ? this.props.organizations.map((u, idx) => {
            const name = u.name;
            const id = u.id;
            return <PracticeItem key={idx} name={name} id={id} selected={this.itemSelected(id)}
                                 onItemClick={this.onItemClick}/>
        }) : '';
        return (
            <>

                <div
                    className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                    <div className="kt-login__head">
                        <div className="flex-container flex-row-reverse"><LanguageSelector className="show"
                                                                                           changeLanguage={this.props.changeLanguage}/>
                        </div>
                    </div>
                    <br/>
                    <div className="card chat-users-card">
                        <div className="card-header select-practice-header">
                            <div className="chat-card-title">
                                <div>{$$('select_a_practice')}</div>
                            </div>
                        </div>

                        <div className="chat-card" style={{overflow:"auto"}}>
                            {practices}
                        </div>

                    </div>
                    <br/>
                    <div>
                        <div className="form-group row">
                            <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                <button onClick={this.onBackClick} className={"btn btn-secondary btn-block "}>
                                    {$$("wizard_back_label")}
                                </button>
                            </div>
                            <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                <button disabled={this.isButtonDisabled()} onClick={() => {
                                    this.props.onSelectPractice(this.state.selectedId, this.state.selectedName)
                                }}
                                        className={"btn btn-success btn-block " + this.disabledClass(this.isButtonDisabled())}>
                                    {$$("select_practice")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

PracticeList.propTypes = {
    organizations: Proptypes.any,
    userInfo: Proptypes.any,
    selectedOrganisation: Proptypes.any,
    onSelectPractice: Proptypes.func,
    changeLanguage: Proptypes.func,
    logout: Proptypes.func,
    SelectOrganisationToManage: Proptypes.func
}

function mapStateToProps(state) {
    return {
        organizations: state.userInfo.data.organizations,
        selectedOrganisation: state.management.selectedOrganisation
    }
}

const mapDispatchToProps = {
    SelectOrganisationToManage,
    logout
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticeList)
